/**
 * List of void elements
 * These elements are not allowed to have children
 * @type {Array}
 */
export default [
  'area',
  'base',
  'br',
  'col',
  'command',
  'embed',
  'hr',
  'img',
  'input',
  'keygen',
  'link',
  'meta',
  'param',
  'source',
  'track',
  'wbr'
];
